import { STATUSES } from "../../components/StatusIcon";
import { IOrder, IOrderItem } from "../../types/orders";

export const getOrderStatus = (items: IOrderItem[]) => {
  const allCanceled = items.every(({ status }) => status === "CANCELED");

  if (allCanceled) {
    return "CANCELED";
  }

  return items.reduce(
    (acc, { status }) => {
      if (status === "CANCELED" || acc === status) {
        return acc;
      }

      if (acc === null) {
        return status;
      }

      return "MIXED";
    },
    null as null | keyof typeof STATUSES,
  );
};

export const getTotal = (order: IOrder) => {
  const itemsTotal = order.items.reduce((acc, item) => {
    if (item.status === "CANCELED") {
      return acc;
    }

    return (acc += item.amount * item.price);
  }, 0);

  const deliveryFee = parseFloat(order.delivery_fee);
  const discount = parseFloat(order.discount);
  const status = getOrderStatus(order.items);

  if (status !== "CANCELED") {
    return itemsTotal + deliveryFee - discount;
  }

  return itemsTotal;
};

export const getTotalCost = (order: IOrder) => {
  const itemsTotal = order.items.reduce((acc, item) => {
    if (item.status === "CANCELED") {
      return acc;
    }

    return (acc += item.amount * item.cost);
  }, 0);

  return itemsTotal;
};

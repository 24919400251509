import { useState } from "react";
import { List, Paper, SelectChangeEvent, Typography } from "@mui/material";
import { sortBy } from "lodash";

import useProducts from "../../services/useProducts";
import { FilterHandler, FreeSoloFilterHandler, Option } from "../../types/core";
import { IProduct } from "../../types/products";
import Filters from "./Filters";
import Product from "./Product";
import styles from "./styles.module.css";

const Products = () => {
  const [baseProduct, setBaseProduct] = useState<null | Option | string>(null);
  const [stock, setStock] = useState("todos");
  const [producer, setProducer] = useState<null | Option>(null);
  const { data: products } = useProducts();

  const handleBaseProductFilter: FreeSoloFilterHandler = (_, newValue) => {
    setBaseProduct(newValue);
  };

  const handleProducerFilter: FilterHandler = (_, newValue) => {
    setProducer(newValue);
  };

  const handleStockFilter = (event: SelectChangeEvent) => {
    setStock(event.target.value);
  };

  if (products) {
    const sortedProducts = sortBy(
      products,
      (product) => `${product.base_product.name} - ${product.producer.name}`,
    );

    const filteredProducts = sortedProducts.filter((product: IProduct) => {
      let filtered = true;
      if (baseProduct !== null) {
        if (typeof baseProduct === "string") {
          const tokens = baseProduct.split(" ");
          filtered =
            filtered &&
            tokens
              .map((token) => token.toLowerCase())
              .every((token) => {
                const productName = product.base_product.name.toLowerCase();
                return productName.includes(token);
              });
        } else {
          filtered = filtered && product.base_product.id === baseProduct.value;
        }
      }

      if (producer !== null) {
        filtered = filtered && product.producer.id === producer.value;
      }

      if (stock !== "todos") {
        if (stock === "em estoque") {
          filtered = filtered && (product.unlimited_stock || product.stock > 0);
        } else if (stock === "baixo estoque") {
          filtered =
            filtered &&
            !product.unlimited_stock &&
            product.stock < 5 &&
            product.stock > 0;
        } else {
          filtered =
            filtered && !product.unlimited_stock && product.stock === 0;
        }
      }

      return filtered;
    });

    return (
      <div className={styles.page}>
        <Typography gutterBottom variant="h3" component="h1">
          Lista de produtos
        </Typography>
        <Filters
          products={products}
          baseProduct={baseProduct}
          onBaseProductFilter={handleBaseProductFilter}
          producer={producer}
          onProducerFilter={handleProducerFilter}
          stock={stock}
          onStockFilter={handleStockFilter}
        />
        <Paper sx={{ marginBottom: "2rem", width: "100%" }}>
          <List>
            {filteredProducts.map((product: IProduct) => (
              <Product key={product.id} product={product} />
            ))}
          </List>
        </Paper>
      </div>
    );
  }

  return <div>Products</div>;
};

export default Products;
